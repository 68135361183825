<template>
    <v-container class="my-10">
        <div class="d-flex align-center">
            <v-icon
                left
                normal
                color="purple"
            >
                fa fa-users-cog
            </v-icon>
            <h2 class="main-heading primary--text">
                {{ $t(`Admin.users.mainHeading`) }}
            </h2>
        </div>
        <v-divider class="mx-10"></v-divider>
        <v-spacer></v-spacer>
        <v-card>
            <v-tabs @change="determineTab($event)">
                <v-tab
                    v-for="tab in tabs"
                    :key="tab.id"
                    style="background: #eeeeee"
                >
                    <v-icon
                        small
                        left
                    >
                        {{ tab.icons.tab }}
                    </v-icon>
                    <v-card-text class="font-weight-bold">
                        {{ $t(`Admin.users.tabs.${tab.name}`) }}
                    </v-card-text>
                </v-tab>
                <!-- Implement Tabs -->
                <v-tab-item
                    v-for="tab in tabs"
                    :key="tab.id"
                >
                    <v-card>
                        <base-card
                            v-if="!getUserStatus_Error"
                            min-height="700"
                        >
                            <template #content>
                                <div class="d-flex justify-space-between px-5 align-center">
                                    <div class="hidden-md-and-down">
                                        <div class="d-flex align-center">
                                            <v-icon
                                                small
                                                color="warning"
                                            >
                                                fa fa-info
                                            </v-icon>
                                            <v-card-text
                                                v-if="tab.id == 1"
                                                    class="text-caption"
                                                >
                                                    Search for a user by:
                                                <span
                                                    class="font-weight-bold"
                                                >
                                                    Org.Type or Org.Name
                                                </span>
                                            </v-card-text>
                                            <v-card-text
                                                v-else
                                                class="text-caption"
                                                >
                                                    Search for deleted user by:
                                                <span class="font-weight-bold">Org.Type or Org.Name</span>
                                            </v-card-text>
                                        </div>
                                    </div>
                                    <form>
                                        <v-text-field
                                            v-model="searchUser"
                                            style="max-width: 400px"
                                            hide-details
                                            label="Search here.."
                                        >
                                            <template #append>
                                                <v-btn
                                                    depressed
                                                    dense
                                                    type="submit"
                                                    color="primary"
                                                    class="ma-2"
                                                    @click.prevent="getUserData()"
                                                >
                                                    <v-icon
                                                        size="14"
                                                        class="mr-2"
                                                    >
                                                        fa-solid fa-plus
                                                    </v-icon>
                                                    Search
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </form>
                                </div>
                                <v-card-text
                                    class="flex-col-between fill-height"
                                >
                                    <v-data-table
                                        :headers="computedHeaders"
                                        :items="users"
                                        :loading="getUserStatus_Pending"
                                        :loading-text="$t(`Admin.dataTable.loadingText`)"
                                        :no-data-text="$t(`Admin.dataTable.noDataText`)"
                                        :no-results-text="$t(`Admin.dataTable.noResultsText`)"
                                        :page.sync="page"
                                        hide-default-footer
                                        disable-sort
                                    >
                                        <template v-slot:[`item.is_active`]="{ item }">
                                            <edit-users-toggle-status
                                                :id="item.id"
                                                :status="item.is_active"
                                            >
                                            </edit-users-toggle-status>
                                        </template>
                                        <template
                                            v-slot:[`item.actions`]="{ item }"
                                        >
                                            <div class="d-flex">
                                                <v-tooltip
                                                    v-if="tab.type == 'available'"
                                                    bottom
                                                    :open-on-focus="false"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            class="mr-2 warning--text"
                                                            small
                                                            @click="handleEditClick(item)"
                                                            v-on="on"
                                                        >
                                                            fas fa-user
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ $t("General.editProfile") }}</span>
                                                </v-tooltip>
                                                <v-tooltip
                                                    v-if="tab.type == 'available'"
                                                    bottom
                                                    :open-on-focus="false"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            class="mr-2 warning--text"
                                                            small
                                                            @click="handleEditPublicProfile(item)"
                                                            v-on="on"
                                                        >
                                                            fas fa-users
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ $t("General.editPublic") }}</span>
                                                </v-tooltip>
                                                <v-tooltip
                                                    bottom
                                                    :open-on-focus="false"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            class="mr-2 ml-5 error--text"
                                                            small
                                                            @click="handleDeleteRestoreUser(item)"
                                                            v-on="on"
                                                        >
                                                            {{ tab.icons.deleteRestore }}
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ $t(`General.${tab.actions.tooltipValue}`) }}</span>
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </v-data-table>
                                    <div v-if="users.length > 0">
                                        <v-divider class="mb-4"></v-divider>

                                        <div class="d-flex flex-column flex-md-row justify-center align-center table-pagination">
                                            <v-pagination
                                                v-if="pageCount > 1"
                                                v-model="page"
                                                size="50px"
                                                class="table-pagination ml-md-auto ml-0"
                                                :length="pageCount"
                                                :total-visible="7"
                                                elevation="0"
                                            >
                                            </v-pagination>
                                            <div class="ml-md-auto ml-0 mt-3 mt-md-0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            outlined
                                                            depressed
                                                            dense
                                                            class="primary white--text"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="downloadUsersToExcel()"
                                                        >
                                                            <v-icon
                                                                small
                                                                left
                                                            >
                                                                fa-solid fa-file-excel
                                                            </v-icon>
                                                            Export .xlsx
                                                        </v-btn>
                                                    </template>
                                                    <span v-if="selectedTab == 0">Download user's data to excel</span>
                                                    <span v-if="selectedTab == 1">Download deleted user's data to excel</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </template>
                        </base-card>
                        <v-card
                            v-if="getUserStatus_Error"
                            min-height="550"
                            class="d-flex justify-center align-center pa-5"
                        >
                            <v-card-text class="text-center">
                                {{ errorMessage }}
                            </v-card-text>
                        </v-card>
                        <confirm-modal
                            :open="openModal"
                            :close.sync="openModal"
                        >
                            <template #content>
                                <span class="modal-content">
                                    {{ $t(`Admin.users.${tab.actions.title}`) }}
                                </span>
                            </template>
                            <template #action>
                                <v-btn
                                    depressed
                                    dense
                                    color="error lighten-1"
                                    class="mr-2"
                                    :loading="false"
                                    @click="confirmDeleteRestoreUser()"
                                >
                                    {{
                                        $t(`Admin.users.${tab.actions.confirm}`)
                                    }}
                                </v-btn>
                            </template>
                        </confirm-modal>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-spacer></v-spacer>
    </v-container>
</template>

<script>
// API layer Imports
import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";
// import { getFormValues } from "../../api/formValuesApi.js";
import {
    getUsers,
    deleteUser,
    getDeletedUsers,
    restoreUsersById,
    downloadUsersExcel,
} from "../../api/adminApi.js";
// import { getCountries } from "../../api/formValuesApi.js";

// Vuex Imports
import { mapGetters } from "vuex";

import BaseCard from "../../components/base/BaseCard.vue";
import EditUsersToggleStatus from "./EditUsersToggleStatus.vue";
import ConfirmModal from "@/components/ConfirmModal";

export default {
    name: "EditUsers",

    components: {
        // EditDialog,
        // UserInfo,
        BaseCard,
        EditUsersToggleStatus,
        ConfirmModal,
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t("Admin.TableHeaders.orgType"),
                    value: "org_type",
                },

                {
                    text: this.$t("Admin.TableHeaders.orgName"),
                    value: "org_name",
                },

                {
                    text: this.$t("Admin.TableHeaders.country"),
                    value: "country_name",
                },

                {
                    text: this.$t("Admin.TableHeaders.created"),
                    value: "created_at",
                },

                {
                    text: this.$t("Admin.TableHeaders.status"),
                    value: "is_active",
                },

                {
                    text: this.$t("Admin.TableHeaders.actions"),
                    value: "actions",
                    align: "end",
                },
            ],
            tabs: [
                {
                    id: 1,
                    name: "availableUsers",
                    icons: {
                        tab: "fa fa-users",
                        deleteRestore: "fa fa-trash",
                    },
                    type: "available",
                    actions: {
                        title: "usersDeleteModal.title",
                        confirm: "usersDeleteModal.confirm",
                        tooltipValue: "deleteUser",
                    },
                },
                {
                    id: 2,
                    name: "restoreUsers",
                    icons: {
                        tab: "fa fa-users-slash",
                        deleteRestore: "fa fa-rotate",
                    },
                    type: "restore",
                    actions: {
                        title: "usersRestoreModal.title",
                        confirm: "usersRestoreModal.confirm",
                        tooltipValue: "restoreUser",
                    },
                },
            ],
            page: 1,
            pageCount: 0,
            errorMessage: "",
            openModal: false,
            userToDelete: {},
            selectedTab: null,
            userToRestore: {},
            users: [],
            searchUser: "",
            getUserStatus: apiStatus.Idle,
            deleteUserStatus: apiStatus.Idle,
            restoreUserStatus: apiStatus.Idle,
            downloadExcelStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed([
            "getUserStatus",
            "getFormValuesStatus",
            "deleteUserStatus",
            "restoreUserStatus",
            "downloadExcelStatus",
        ]),

        ...mapGetters({
            getOrgTypes: "formValues/getOrgTypes",
        }),

        // Computed property to determine headers based on tab selected
        computedHeaders() {
            if (this.selectedTab == 0) {
                return this.headers;
            } else {
                return this.headers.filter((header) => {
                    return header.value != "is_active";
                });
            }
        },

        showTablePagination() {
            return Object.keys(this.users).length > this.itemsPerPage;
        },
    },

    methods: {
        determineTab(toggledTabEvent) {
            this.users = [];
            this.selectedTab = toggledTabEvent;
        },
        async getUserData() {
            this.getUserStatus = apiStatus.Pending;
            let payload = {
                page: this.page,
                items: 7,
                search: this.searchUser,
            };

            let determineApiCall = this.selectedTab == 0 ? getUsers : getDeletedUsers;

            const { response, error } = await withAsync(determineApiCall, payload);

            if (error) {
                this.getUserStatus = apiStatus.Error;
                this.errorMessage = error.response.status === 500 ? error.response.statusText : error.response.data;
                return;
            }

            setTimeout(() => {
                this.getUserStatus = apiStatus.Success;

                this.pageCount = response.data.last_page;

                this.users = response.data.data;
            }, 500);
        },

        handleEditClick(item) {
            this.$router.push({ name: "UserProfile", params: { id: item.id } });
        },

        handleEditPublicProfile(item) {
            this.$router.push({
                name: "PublicProfile",
                params: { id: item.id },
            });
        },
        handleDeleteRestoreUser(item) {
            this.openModal = true;
            this.selectedTab == 0 ? (this.userToDelete = item) : (this.userToRestore = item);
        },
        confirmDeleteRestoreUser() {
            return this.selectedTab == 0 ? this.confirmDeleteUser() : this.confirmRestoreUser();
        },
        async confirmDeleteUser() {
            this.deleteUserStatus = apiStatus.Pending;

            const payload = {
                id: this.userToDelete.id,
            };

            const { response, error } = await withAsync(deleteUser, payload);

            if (error) {
                this.deleteUserStatus = apiStatus.Error;
                return;
            }

            this.deleteUserStatus = apiStatus.Success;
            this.getUserData();
            this.openModal = false;
        },
        async confirmRestoreUser() {
            this.restoreUserStatus = apiStatus.Pending;

            const payload = {
                id: this.userToRestore.id,
            };

            const { response, error } = await withAsync(
                restoreUsersById,
                payload
            );

            if (error) {
                this.restoreUserStatus = apiStatus.Error;
                return;
            }

            this.restoreUserStatus = apiStatus.Success;
            this.getUserData();
            this.openModal = false;
        },
        async downloadUsersToExcel() {
            this.downloadExcelStatus = apiStatus.Pending;

            const payload = {
                deleted: true
            };
            
            const determinePayload = this.selectedTab == 1 ? payload : null; 

            const { response, error } = await withAsync(downloadUsersExcel, determinePayload);

            if (error) {
                this.downloadExcelStatus = apiStatus.Error;
                return;
            }

            try {
                const blobFile = new Blob([response.data], { type: response.headers["content-type"] });

                const blobUrl = URL.createObjectURL(blobFile);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.style.display = "none";

                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            }
            catch (error) {
                console.error("Failed to download excel:", error);
            }

            this.downloadExcelStatus = apiStatus.Success;
        },
    },

    watch: {
        selectedTab(newVal) {
            this.getUserData();
            if (newVal == 1) {
                this.page = 1;
            }
        },
        page(newVal) {
            this.getUserData();
        },
        searchUser(newVal) {
            if (newVal == "") {
                this.page = 1;
                this.getUserData();
            }
        },
    },

    /**
     * On create set the apiStatus and execute the function
     * that will fetch form values first and through it, other calls are being made
     */

    created() {
        this.apiStatus = apiStatus;
        this.getUserData();
    },
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
</style>
