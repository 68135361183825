<template>
    <div class="d-flex">
        <v-tooltip
            bottom
            :open-on-focus="false"
            :color="getStatusColor(isEnabled)"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    small
                    :color="getStatusColor(isEnabled)"
                    v-on="on"
                >
                    fas fa-user
                </v-icon>
            </template>
            <span>{{ statusText}}</span>
        </v-tooltip>
        <v-switch
            v-model="isEnabled"
        ></v-switch>
        <confirm-modal :open="showDialog" :close.sync="showDialog">
            <template #content>
                <span class="modal-content">
                    {{ computedModalText }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="primary"
                    class="mr-2"
                    :loading="userStatus_Pending"
                    @click="handleUserStatus()"
                >
                    {{ $t(`Admin.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>
    </div>
</template>

<script>
import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";
import { toggleUserStatus } from "../../api/adminApi.js";

import ConfirmModal from "../../components/ConfirmModal.vue";

export default {
    name: "ToggleStatus",
    props: {
        status: {
            type: Boolean,
            required: true,
        },

        id: {
            type: [String, Number],
            required: true,
        }
    },

    components: {
        ConfirmModal
    },


    data() {
        return {
            startingValue: this.status,
            isEnabled: this.status,
            userStatus: apiStatus.Idle,

            showDialog: false,
        };
    },

    computed: {
        ...apiStatusComputed(["userStatus"]),

        computedModalText(){
           return `Are you sure you want to ${this.isEnabled ? 'enable' : 'disable'} this user?`
        },

        statusText(){
            return this.isEnabled ? this.$t('General.enabled') : this.$t('General.disabled')
        }
    },

    methods: {
        getStatusColor(status) {
            if (status) {
                return "primary";
            } else {
                return "error";
            }
        },

        async handleUserStatus() {
            this.userStatus = apiStatus.Pending;

            const payload = {
                id: this.id,
                enable: this.isEnabled,
            };

            const { response, error } = await withAsync(
                toggleUserStatus,
                payload
            );

            if (error) {
                this.userStatus = apiStatus.Error;
                return;
            }
            this.userStatus = apiStatus.Success;
            this.showDialog = false;
            this.startingValue = this.isEnabled
            this.$nextTick(() => {
                this.userStatus = apiStatus.Idle;
            })
        },
    },

    watch: {
        isEnabled(newVal) {
            if (this.userStatus_Idle) {
                this.showDialog = true;
            }
        },

        showDialog(newVal){
            if(!newVal && this.userStatus_Idle) this.isEnabled = this.startingValue;
        }
    },
};
</script>
